import { Form } from "antd";
import { useCallback, useContext, useState } from "react";

import {
  currentDepotInvestmentSettingState,
  currentDepotUserUriState
} from "@states/current.depot.state";
import { useRefreshInvestmentSettings } from "@states/investment-settings.state";
import useThemedModal from "@theme/hook/useThemedModal";
import { openIndependentInfoModal } from "@theme/modal/openThemedModal";
import { assertNotEmpty } from "smavesto.core/lib/utils/typeguards/assertNotEmpty";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type InvestmentSettingsDto from "smavesto.core/lib/types/dto/user/InvestmentSettingsDto";

import { InvestmentConfirmContent } from "./InvestmentConfirmContent";

export const useInvestmentModalForm = (destroyModal: () => void) => {
  const [form] = Form.useForm();

  const { contextHolder, openModal } = useThemedModal();

  const labels = useOnboardingLabels();

  const [, userUri] = useAsyncSelector(currentDepotUserUriState);

  const [isSubmit, setIsSubmit] = useState(false);

  const { theme } = useContext(ThemeContext);

  const [handleApiError] = useApiErrorHandler();

  const setInvestmentSettingsFields = useCallback(
    (res?: InvestmentSettingsDto) =>
      res &&
      form.setFieldsValue({
        ...res,
        emergingMarkets: !res.noEmergingMarkets,
        foreignCurrency: !res.noForeignCurrency
      }),
    [form]
  );

  useAsyncSelector(
    currentDepotInvestmentSettingState,
    setInvestmentSettingsFields
  );

  const refreshInvestmentSettings =
    useRefreshInvestmentSettings("currentDepotOwner");

  const submit = async (body: InvestmentSettingsDto) => {
    assertNotEmpty(userUri);

    setIsSubmit(true);

    const putInvestmentSettingsResponse =
      await smavestoCore.services.user.setInvestmentSettings(userUri, body);

    if (isApiErrorResponse(putInvestmentSettingsResponse)) {
      handleApiError(putInvestmentSettingsResponse, "modal", "error");
      setIsSubmit(false);
    }

    refreshInvestmentSettings();
    destroyModal();
    openIndependentInfoModal(
      labels.investmentSettingsModal.investmentSettingsInfo,
      theme
    );

    setIsSubmit(false);
  };

  const onFinish = async (values: any) => {
    const body = {
      noEmergingMarkets: !values?.emergingMarkets,
      noForeignCurrency: !values?.foreignCurrency,
      onlySustainableCompanies: values?.onlySustainableCompanies
    };

    if (body.onlySustainableCompanies) {
      openModal("confirm", {
        ...labels.investmentRisk.modalOptions.onlySustainableCompanies,
        content: <InvestmentConfirmContent />,
        onOk: () => submit(body),
        onCancel: () => form.setFieldsValue({ onlySustainableCompanies: false })
      });
      return;
    }

    submit(body);
  };

  return { form, isSubmit, onFinish, contextHolder };
};
