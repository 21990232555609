import PopoverInfo from "@components/form/popover-info/PopoverInfo";
import CancellationTooltip from "@components/form/tooltips/CancellationTooltip";
import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import {
  getTextForInfo,
  parseRiskNumValueToLabel
} from "@page-components/onboarding/FinancialPosition/strategy/helpers";
import { EntriesItem } from "@page-components/onboarding/Summary/EntriesItem";
import { getConfirm } from "@page-components/onboarding/Summary/helpers";
import { currentDepotInvestmentSettingState } from "@states/current.depot.state";
import { depotDetailsState } from "@states/depot.state";
import { SButton } from "@styled-components/form/SButtons";
import { SCustomDivider } from "@styled-components/SCustomDivider";
import { SH2, SSection } from "@styled-components/STags";
import useThemedModal from "@theme/hook/useThemedModal";

import ReallocationTooltip from "@src/components/layout/tooltips/ReallocationTooltip";
import formatLinebreaks from "@src/utils/format/formatLinebreaks";

import { ThemeContext } from "@src/pages/_app";
import useIsMobile from "@src/theme/hook/useIsMobile";
import { useContext } from "react";
import { onModalFormOpen } from "../../Profile/helpers";
import { InvestmentModalForm } from "./InvestmentModalForm";
import { RiskModalForm } from "./RiskModalForm";

interface InvestmentSettingsProps {
  isUnderage: boolean;
}

export const InvestmentSettings: React.FunctionComponent<
  InvestmentSettingsProps
> = ({ isUnderage }) => {
  const { contextHolder, openModal } = useThemedModal();

  const [, depotDetails] = useAsyncSelector(depotDetailsState);

  const [, investmentSettings] = useAsyncSelector(
    currentDepotInvestmentSettingState
  );

  const labels = useOnboardingLabels("dashboard");

  const { theme } = useContext(ThemeContext);

  const isMobile = useIsMobile();

  return (
    <SSection>
      <SH2 fontWeight={700} margin="0 0 16px" color={theme.palette.primary}>
        Anlagerisiko
      </SH2>

      {depotDetails && (
        <EntriesItem
          mobileLayout
          label="Aktuelles Anlagerisiko:"
          alignSelectionBottom
        >
          {parseRiskNumValueToLabel(depotDetails?.anlageRisiko ?? 15)} (
          {depotDetails.anlageRisiko}% max. Drawdown)
          <PopoverInfo
            text={getTextForInfo(
              parseRiskNumValueToLabel(depotDetails?.anlageRisiko ?? 15),
              isUnderage
            )}
            title={parseRiskNumValueToLabel(depotDetails?.anlageRisiko ?? 15)}
          />
        </EntriesItem>
      )}

      {depotDetails?.desiredAnlageRisiko && (
        <EntriesItem
          mobileLayout
          label={
            <>
              Mit der nächsten Reallokation
              <ReallocationTooltip />:
            </>
          }
          alignSelectionBottom
        >
          {parseRiskNumValueToLabel(
            depotDetails.desiredAnlageRisiko ?? depotDetails.anlageRisiko
          )}{" "}
          ({depotDetails?.desiredAnlageRisiko}% max. Drawdown)
          <PopoverInfo
            text={getTextForInfo(
              parseRiskNumValueToLabel(depotDetails.desiredAnlageRisiko),
              isUnderage
            )}
            title={parseRiskNumValueToLabel(depotDetails?.desiredAnlageRisiko)}
          />
        </EntriesItem>
      )}

      <SCustomDivider style={{ width: "86%", margin: "40px auto" }} />

      <EntriesItem
        mobileLayout
        label={
          isUnderage
            ? "Ich möchte für mein Kind nur in nachhaltige Anlageformen investieren:"
            : "Ich möchte nur in nachhaltige Anlageformen investieren:"
        }
        alignSelectionBottom
      >
        {getConfirm(investmentSettings?.onlySustainableCompanies)}
        <PopoverInfo
          title="Nachhaltige Anlageformen"
          text={
            <>
              Wir bewerten, in unserer nachhaltigen Variante, alle Investitionen
              in unserem Vermögensmanagement nach sogenannten ESG-Kriterien.
              Erfahren Sie mehr in unserer{" "}
              <a
                href="https://smavesto.de/files/32/Nachhaltigkeitsbezogene_Offenlegung_der_Smavesto_GmbH_06_2024_1.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <u>Nachhaltigkeitsrichtlinie</u>
              </a>
              .
            </>
          }
        />
      </EntriesItem>

      <EntriesItem
        mobileLayout
        label={
          isUnderage
            ? "Ich möchte für mein Kind auch in Fremdwährungen investieren:"
            : "Ich möchte auch in Fremdwährungen investieren:"
        }
        alignSelectionBottom
      >
        {getConfirm(!investmentSettings?.noForeignCurrency)}
        <PopoverInfo
          title={labels.investmentRisk.noForeignCurrency.label}
          text={labels.investmentRisk.noForeignCurrency.info}
        />
      </EntriesItem>

      <EntriesItem
        mobileLayout
        label={
          isUnderage
            ? "Ich möchte für mein Kind auch in Emerging Markets investieren:"
            : "Ich möchte auch in Emerging Markets investieren:"
        }
        alignSelectionBottom
      >
        {getConfirm(!investmentSettings?.noEmergingMarkets)}
        <PopoverInfo
          title={labels.investmentRisk.noEmerginMarkets.label}
          text={formatLinebreaks(labels.investmentRisk.noEmerginMarkets.info)}
        />
      </EntriesItem>

      <SSection
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin="40px auto"
        flexWrap="wrap"
      >
        <CancellationTooltip>
          <SButton
            size="large"
            type="primary"
            style={{ margin: 12, width: isMobile ? "100%" : 240 }}
            onClick={() => {
              const modalInst = openModal("confirm", {
                ...labels.investmentSettingsModal.investmentSettingsConfirm,
                onOk: () => {
                  onModalFormOpen(openModal, InvestmentModalForm, 900, {
                    isUnderage
                  })();
                  modalInst.destroy();
                }
              });
            }}
          >
            Präferenzen ändern
          </SButton>
        </CancellationTooltip>
        <CancellationTooltip>
          <SButton
            size="large"
            type="primary"
            style={{ margin: 12, width: isMobile ? "100%" : 240 }}
            onClick={() => {
              const modalInst = openModal("confirm", {
                ...labels.investmentSettingsModal.investmentRiskConfirm,
                onOk: () => {
                  onModalFormOpen(openModal, RiskModalForm, undefined, {
                    source: "dashboard",
                    isUnderage
                  })();
                  modalInst.destroy();
                }
              });
            }}
          >
            Anlagerisiko ändern
          </SButton>
        </CancellationTooltip>
      </SSection>

      {contextHolder}
    </SSection>
  );
};
